import { SbSelectLazyloadSingleComponent } from './sb-select-lazyload-single/sb-select-lazyload-single.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DropdownDonViOneComponent } from './dropdown-donvi-one/dropdown-donvi-one.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { InputHtmlComponent } from './input-html/input-html.component';
import { ViewImageComponent } from './view-image/view-image/view-image.component';
import { NzImageModule } from 'ng-zorro-antd/image';
import { FileListComponent } from './file-list/file-list.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { SpanCurrencyComponent } from './span-currency/span-currency.component';
import { InputCMNDComponent } from './input-cmnd/input-cmnd.component';
import { BtnSaveComponent } from './button/btn-save/btn-save.component';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { SbDropdownComponent } from './sb-dropdown/sb-dropdown.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { ViewHotlineComponent } from './view-hotline/view-hotline.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { ImageInTableComponent } from './image-components/image-in-table/image-in-table.component';
import { DatePickerRangeComponent } from './date-picker-range/date-picker-range.component';
import { ImageModalComponent } from './image-components/image-modal/image-modal.component';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzInputModule } from 'ng-zorro-antd/input';
import { SharedModule } from '../shared/shared.module';
import { ImageCropComponent } from './image-crop/image-crop.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CacheFilterSaveFormComponent } from './cache-filter-save-form/cache-filter-save-form.component';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { SearchAndFilterComponent } from './button/search-and-filter/search-and-filter.component';
import { TableSettingComponent } from './table-setting/table-setting.component';
import { CdkDrag, CdkDropList, CdkDragHandle } from '@angular/cdk/drag-drop';
import { FilterTacnghiepComponent } from './filter-tacnghiep/filter-tacnghiep.component';
import { FilterTrangThaiComponent } from './filter-trang-thai/filter-trang-thai.component';
import { OmicallViewDialComponent } from './switchboard/omicall-view-dial/omicall-view-dial.component';
import { MyhubViewHotlineComponent } from './switchboard/myhub-view-hotline/myhub-view-hotline.component';
import { ButtonCallComponent } from './switchboard/button-call/button-call.component';
import { PopupResultComponent } from './popup-result/popupresult';
import { NzResultModule } from 'ng-zorro-antd/result';
import { QrCodeModule } from 'ng-qrcode';
import { FilterNhomTienIchComponent } from './filter-nhom-tienich/filter-nhom-tienich.component';
import { InputTagComponent } from './input-tag/input-tag.component';
import { FilterThuKhoTacnghiepComponent } from './filter-thukho-tacnghiep/filter-thukho-tacnghiep.component';
import { DropDownListSaleMarketingComponent } from './drop-down-list/drop-down-list-sale-marketing/drop-down-list-sale-marketing.component';
import { DropDownListSanPhamChaConComponent } from './drop-down-list/drop-down-list-san-pham-cha-con/drop-down-list-san-pham-cha-con.component';
import { DropDownListNhomSaleMarketingComponent } from './drop-down-list/drop-down-list-nhom-sale-marketing/drop-down-list-nhom-sale-marketing.component';
import { DropDownListSaleMarketingAutoCompleteComponent } from './drop-down-list/drop-down-list-sale-marketing-auto-complete/drop-down-list-sale-marketing-auto-complete.component';
import { DropDownListLandingPageSoureComponent } from './drop-down-list/drop-down-list-landing-page-soure/drop-down-list-landing-page-soure.component';
import { DropDownChiNhanhComponent } from './drop-down-list/drop-down-chi-nhanh/drop-down-chi-nhanh.component';
import { DropDownDonViVanChuyenComponent } from './drop-down-list/drop-down-don-vi-van-chuyen/drop-down-don-vi-van-chuyen.component';
import { DropDownTrangThaiGiaoHangComponent } from './drop-down-list/drop-down-trang-thai-giao-hang/drop-down-trang-thai-giao-hang.component';
import { DropDownDmTacNghiepComponent } from './drop-down-list/drop-down-dm-tac-nghiep/drop-down-dm-tac-nghiep.component';
import { DropDownDmKetQuaTacNghiepComponent } from './drop-down-list/drop-down-dm-ket-qua-tac-nghiep/drop-down-dm-ket-qua-tac-nghiep.component';
import { ButtonMicComponent } from './switchboard/button-mic/button-mic.component';
import { TableResizeComponent } from './table-resize/table-resize.component';
import { DropDownListDmTacNghiepMixComponent } from './drop-down-list/drop-down-list-dm-tac-nghiep-mix/drop-down-list-dm-tac-nghiep-mix.component';
import { SbSelectComponent } from './sb-select/sb-select.component';
import { SBCheckBoxComponent } from './sb-checkbox/sb-checkbox.component';
import { DropDownListCareDonComponent } from './drop-down-list/drop-down-list-care-don/drop-down-list-care-don.component';
import { DropDownListLoaiCuocGoiComponent } from './drop-down-list/drop-down-list-loai-cuoc-goi/drop-down-list-loai-cuoc-goi.component';
import { DropDownListKhoComponent } from './drop-down-list/drop-down-list-kho/drop-down-list-kho.component';
import { DropDownListNhanVienKhoComponent } from './drop-down-list/drop-down-list-nhan-vien-kho/drop-down-list-nhan-vien-kho.component';
import { MyHubHistoryCallComponent } from './switchboard/myhub-history-call/myhub-history-call.component';
import { DropDownListKhoVaNhanVienComponent } from './drop-down-list/drop-down-list-kho-va-nhan-vien/drop-down-list-kho-va-nhan-vien.component';
import { ThResizerDirective } from './table-resize/th-resizer-directive/th-resizer-directive.component';
import { AutoIncrementService } from './table-resize/auto-increment.service';
import { TableResizeNotSaveComponent } from './table-resize/table-resize-not-save/table-resize-not-save.component';
import { ThDirectiveComponent } from './table-resize/th-directive/th-directive.component';
import { Sb2SelectComponent } from './work/sb2-select/sb2-select.component';
import { ImagesInTableComponent } from './image-components/images-in-table/images-in-table.component';
import { ReadMoreComponent } from './read-more/read-more.component';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { SbSelectLazyloadGroupComponent } from './sb-select-lazyload-group/sb-select-lazyload-group.component';
import { SbDropdownLoadmoreComponent } from './sb-dropdown-loadmore/sb-dropdown-loadmore.component';
import { DropDownListKieuNgayComponent } from './drop-down-list/drop-down-list-kieu-ngay/drop-down-list-kieu-ngay.component';
import { DropDownListMixDatepickerKieungayComponent } from './drop-down-list/drop-down-list-mix-datepicker-kieungay/drop-down-list-mix-datepicker-kieungay.component';
import { SbSelectLoadmoreComponent } from './sb-select-loadmore/sb-select-loadmore.component';
import { DropDownListSaleMarketingAutoCompleteTettoComponent } from './drop-down-list/drop-down-list-sale-marketing-auto-complete-tetto/drop-down-list-sale-marketing-auto-complete-tetto.component';
import { SbSelectLoadmoreGroupComponent } from './sb-select-loadmore-group/sb-select-loadmore-group.component';

const antdModule = [
  NzButtonModule,
  NzModalModule,
  NzUploadModule,
  NzImageModule,
  NzIconModule,
  NzRadioModule,
  NzTimePickerModule,
  NzPopoverModule,
  NzDropDownModule,
  NzSpinModule,
  NzInputModule,
  NzCardModule,
  NzAvatarModule,
  NzRateModule,
  NzBadgeModule,
  NzProgressModule,
  NzTableModule,
  NzTimelineModule,
  NzTabsModule,
  NzTagModule,
  NzListModule,
  NzCalendarModule,
  NzToolTipModule,
  NzFormModule,
  NzSelectModule,
  NzPaginationModule,
  NzDatePickerModule,
  NzCheckboxModule,
  NzMessageModule,
  NzTreeModule,
  NzTreeSelectModule,
  NzSkeletonModule,
  NzDividerModule,
  NzAutocompleteModule,
  NzInputNumberModule,
  NgSelectModule,
  NzCommentModule,
  NzAutocompleteModule,
  NzTimePickerModule,
  NzCollapseModule,
  NzTypographyModule,
  NzSwitchModule,
  CdkDropList,
  CdkDragHandle,
  CdkDrag,
  NzResultModule,
  NzAutocompleteModule,
];
@NgModule({
  declarations: [
    FileUploadComponent,
    DatePickerComponent,
    DropdownDonViOneComponent,
    InputHtmlComponent,
    ViewImageComponent,
    FileListComponent,
    InputNumberComponent,
    SpanCurrencyComponent,
    InputCMNDComponent,
    BtnSaveComponent,
    SbDropdownComponent,
    ViewHotlineComponent,
    ImageModalComponent,
    DatePickerRangeComponent,
    ImageInTableComponent,
    ImageCropComponent,
    CacheFilterSaveFormComponent,
    SearchAndFilterComponent,
    TableSettingComponent,
    FilterTacnghiepComponent,
    FilterTrangThaiComponent,
    OmicallViewDialComponent,
    MyhubViewHotlineComponent,
    ButtonCallComponent,
    PopupResultComponent,
    FilterNhomTienIchComponent,
    FilterThuKhoTacnghiepComponent,
    InputTagComponent,
    DropDownListSaleMarketingComponent,
    DropDownListSanPhamChaConComponent,
    DropDownListNhomSaleMarketingComponent,
    DropDownListSaleMarketingAutoCompleteComponent,
    DropDownListLandingPageSoureComponent,
    DropDownChiNhanhComponent,
    DropDownDonViVanChuyenComponent,
    DropDownTrangThaiGiaoHangComponent,
    DropDownDmTacNghiepComponent,
    DropDownDmKetQuaTacNghiepComponent,
    ButtonMicComponent,
    MyHubHistoryCallComponent,
    TableResizeComponent,
    DropDownListDmTacNghiepMixComponent,
    SbSelectComponent,
    SBCheckBoxComponent,
    DropDownListCareDonComponent,
    DropDownListLoaiCuocGoiComponent,
    DropDownListKhoComponent,
    DropDownListNhanVienKhoComponent,
    DropDownListKhoVaNhanVienComponent,
    ThResizerDirective,
    TableResizeNotSaveComponent,
    ThDirectiveComponent,
    Sb2SelectComponent,
    ImagesInTableComponent,
    ReadMoreComponent,
    SbSelectLazyloadSingleComponent,
    SbSelectLazyloadGroupComponent,
    SbDropdownLoadmoreComponent,
    DropDownListKieuNgayComponent,
    DropDownListMixDatepickerKieungayComponent,
    SbSelectLoadmoreComponent,
    SbSelectLoadmoreGroupComponent,
    DropDownListSaleMarketingAutoCompleteTettoComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule,
    OverlayModule,
    NgSelectModule,
    ReactiveFormsModule,
    SharedModule,
    CKEditorModule,
    ...antdModule,
    NgxMaskDirective,
    NgxMaskPipe,
    GalleryModule.withConfig({
      disableThumb: true,
    }),
    LightboxModule.withConfig({
      panelClass: 'fullscreen',
    }),
    ImageCropperModule,
    QrCodeModule,
  ],
  providers: [provideNgxMask(), AutoIncrementService],
  exports: [
    FileUploadComponent,
    DatePickerComponent,
    DropdownDonViOneComponent,
    InputHtmlComponent,
    ViewImageComponent,
    FileListComponent,
    InputNumberComponent,
    SpanCurrencyComponent,
    InputCMNDComponent,
    BtnSaveComponent,
    SbDropdownComponent,
    ViewHotlineComponent,
    ImageModalComponent,
    DatePickerRangeComponent,
    ImageInTableComponent,
    ImageCropComponent,
    SearchAndFilterComponent,
    TableSettingComponent,
    FilterTacnghiepComponent,
    FilterTrangThaiComponent,
    OmicallViewDialComponent,
    MyhubViewHotlineComponent,
    ButtonCallComponent,
    PopupResultComponent,
    FilterNhomTienIchComponent,
    FilterThuKhoTacnghiepComponent,
    InputTagComponent,
    DropDownListSaleMarketingComponent,
    DropDownListSanPhamChaConComponent,
    DropDownListNhomSaleMarketingComponent,
    DropDownListSaleMarketingAutoCompleteComponent,
    DropDownListLandingPageSoureComponent,
    DropDownChiNhanhComponent,
    DropDownDonViVanChuyenComponent,
    DropDownTrangThaiGiaoHangComponent,
    DropDownDmTacNghiepComponent,
    DropDownDmKetQuaTacNghiepComponent,
    ButtonMicComponent,
    MyHubHistoryCallComponent,
    TableResizeComponent,
    DropDownListDmTacNghiepMixComponent,
    SbSelectComponent,
    SBCheckBoxComponent,
    DropDownListCareDonComponent,
    DropDownListLoaiCuocGoiComponent,
    DropDownListKhoComponent,
    DropDownListNhanVienKhoComponent,
    DropDownListKhoVaNhanVienComponent,
    ThResizerDirective,
    TableResizeNotSaveComponent,
    ThDirectiveComponent,
    Sb2SelectComponent,
    ImagesInTableComponent,
    ReadMoreComponent,
    SbSelectLazyloadSingleComponent,
    SbSelectLazyloadGroupComponent,
    SbDropdownLoadmoreComponent,
    DropDownListKieuNgayComponent,
    DropDownListMixDatepickerKieungayComponent,
    SbSelectLoadmoreComponent,
    SbSelectLoadmoreGroupComponent,
    DropDownListSaleMarketingAutoCompleteTettoComponent,
  ],
})
export class ComponentsModule {}
